import * as Sentry from '@sentry/nextjs';
import { useCallback } from 'react';
export const useSentry = () => {
    const pushErrorToSentry = useCallback(({ category = 'Authentication', error, message = '', payload, }) => {
        Sentry.addBreadcrumb({
            category,
            data: {
                payload,
            },
            message,
        });
        Sentry.captureException(error);
    }, []);
    return { pushErrorToSentry };
};
