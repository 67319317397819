"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.converter = exports.signInWithEmailAndPassword = exports.confirmPasswordReset = exports.verifyEmail = exports.getCurrentFirebaseUser = exports.createUserWithEmailAndPassword = exports.verifyBeforeUpdateEmail = exports.signOutFirebase = exports.getAuthIdToken = exports.db = exports.auth = exports.app = exports.firebaseConfig = exports.reauthenticateWithCredential = exports.onIdTokenChanged = exports.onAuthStateChanged = exports.isFirebaseError = exports.getUpdateEmailErrorMessage = exports.getSignupErrorMessage = exports.getLoginErrorMessage = exports.getEmailVerificationErrorMessage = void 0;
var error_1 = require("./error");
Object.defineProperty(exports, "getEmailVerificationErrorMessage", { enumerable: true, get: function () { return error_1.getEmailVerificationErrorMessage; } });
Object.defineProperty(exports, "getLoginErrorMessage", { enumerable: true, get: function () { return error_1.getLoginErrorMessage; } });
Object.defineProperty(exports, "getSignupErrorMessage", { enumerable: true, get: function () { return error_1.getSignupErrorMessage; } });
Object.defineProperty(exports, "getUpdateEmailErrorMessage", { enumerable: true, get: function () { return error_1.getUpdateEmailErrorMessage; } });
Object.defineProperty(exports, "isFirebaseError", { enumerable: true, get: function () { return error_1.isFirebaseError; } });
const app_1 = require("firebase/app");
const auth_1 = require("firebase/auth");
const firestore_1 = require("firebase/firestore");
var auth_2 = require("firebase/auth");
Object.defineProperty(exports, "onAuthStateChanged", { enumerable: true, get: function () { return auth_2.onAuthStateChanged; } });
Object.defineProperty(exports, "onIdTokenChanged", { enumerable: true, get: function () { return auth_2.onIdTokenChanged; } });
Object.defineProperty(exports, "reauthenticateWithCredential", { enumerable: true, get: function () { return auth_2.reauthenticateWithCredential; } });
exports.firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY ?? '',
    authDomain: process.env.FIREBASE_AUTH_DOMAIN ?? '',
    projectId: process.env.FIREBASE_PROJECT_ID ?? '',
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET ?? '',
    messagingSenderId: process.env.FIREBASE_MESSAGE_SENDER_ID ?? '',
    measurementId: process.env.FIREBASE_MEASUREMENT_ID ?? '',
    appId: process.env.FIREBASE_APP_ID ?? '',
};
exports.app = !(0, app_1.getApps)().length ? (0, app_1.initializeApp)(exports.firebaseConfig) : (0, app_1.getApp)();
exports.auth = (0, auth_1.getAuth)(exports.app);
exports.db = (0, firestore_1.getFirestore)(exports.app);
const getAuthIdToken = async () => {
    await exports.auth.authStateReady();
    if (!exports.auth.currentUser)
        return null;
    return await exports.auth.currentUser.getIdToken();
};
exports.getAuthIdToken = getAuthIdToken;
const signOutFirebase = () => {
    const auth = (0, auth_1.getAuth)(exports.app);
    return (0, auth_1.signOut)(auth);
};
exports.signOutFirebase = signOutFirebase;
const verifyBeforeUpdateEmail = async (newEmail, password, actionCodeSettings) => {
    const auth = (0, auth_1.getAuth)(exports.app);
    const user = auth.currentUser;
    // ユーザーがログインしていない場合はエラーをスロー
    if (!user?.email)
        throw new Error('User is not logged in');
    // 現在のメールアドレスとパスワードを使用して認証情報を作成
    const credential = auth_1.EmailAuthProvider.credential(user.email, password);
    //再認証を行い、成功した場合はメールアドレスの更新を行う
    const newCredential = await (0, auth_1.reauthenticateWithCredential)(user, credential);
    await (0, auth_1.verifyBeforeUpdateEmail)(newCredential.user, newEmail, actionCodeSettings);
};
exports.verifyBeforeUpdateEmail = verifyBeforeUpdateEmail;
const createUserWithEmailAndPassword = (email, password) => {
    const auth = (0, auth_1.getAuth)(exports.app);
    return (0, auth_1.createUserWithEmailAndPassword)(auth, email, password);
};
exports.createUserWithEmailAndPassword = createUserWithEmailAndPassword;
const getCurrentFirebaseUser = () => {
    const auth = (0, auth_1.getAuth)(exports.app);
    return auth.currentUser;
};
exports.getCurrentFirebaseUser = getCurrentFirebaseUser;
const verifyEmail = (oobCode) => {
    const auth = (0, auth_1.getAuth)(exports.app);
    return (0, auth_1.applyActionCode)(auth, oobCode);
};
exports.verifyEmail = verifyEmail;
const confirmPasswordReset = (oobCode, newPassword) => {
    const auth = (0, auth_1.getAuth)(exports.app);
    return (0, auth_1.confirmPasswordReset)(auth, oobCode, newPassword);
};
exports.confirmPasswordReset = confirmPasswordReset;
const signInWithEmailAndPassword = (email, password) => {
    const auth = (0, auth_1.getAuth)(exports.app);
    return (0, auth_1.signInWithEmailAndPassword)(auth, email, password);
};
exports.signInWithEmailAndPassword = signInWithEmailAndPassword;
const converter = () => {
    return {
        toFirestore: (data) => {
            return data;
        },
        fromFirestore: (snapshot) => {
            const data = snapshot.data();
            return data;
        },
    };
};
exports.converter = converter;
