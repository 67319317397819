export { calculateClientWidth } from './calc'
export {
	convertToHalfWidth,
	createConverter,
	getFilterParamsFromHash,
	parseBoolean,
	transformNumber,
	transformNumberArray,
	transformString,
	transformStringArray,
} from './converter'
export {
	getCookie,
	getFirebaseIdToken,
	getHospitalIdFromCookie,
	hospitalAccessToken,
	hospitalRefreshToken,
	removeCookie,
	setClinicAliasToCookie,
	setFirebaseIdToken,
	setCookie,
	setHospitalIdToCookie,
} from './cookie'
export { extractDiff } from './diff'
export { addCommas } from './format'
export { makeGoogleMapUrl } from './link'
export { ellipsisStyles } from './muiStyles'
export { extractHash, scrollTo } from './scroll'
export { sleep } from './sleep'
export { pickFirst } from './typeGuard'
export { atLeastOne } from './validation'
