import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/nextjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getLoginErrorMessage, isFirebaseError } from '@atnd/firebase';
import { getSigninSchema } from '../../../validation';
import { useSnackbar } from '../../notification/useSnackbar';
const initialState = {
    email: '',
    password: '',
};
export const useSignin = ({ onSubmit, signInWithEmail, }) => {
    const { openSnackbar } = useSnackbar();
    const signinSchema = getSigninSchema();
    const [isLoading, setIsLoading] = useState(false);
    const { control, handleSubmit } = useForm({
        mode: 'onSubmit',
        resolver: zodResolver(signinSchema),
        defaultValues: initialState,
    });
    const submit = handleSubmit(async (data) => {
        try {
            setIsLoading(true);
            const user = await signInWithEmail({
                email: data.email,
                password: data.password,
            });
            await onSubmit?.({ ...user, ...data });
        }
        catch (e) {
            const message = getLoginErrorMessage(e);
            openSnackbar(message, 'error');
            if (isFirebaseError(e) && e.code === 'auth/user-not-found') {
                Sentry.addBreadcrumb({
                    category: 'Authentication',
                    data: {
                        payload: data,
                    },
                    message,
                });
                Sentry.captureException(e);
            }
        }
        finally {
            setIsLoading(false);
        }
    }, (error) => {
        console.error(error);
    });
    return {
        control,
        submit,
        isLoading,
        setIsLoading,
    };
};
