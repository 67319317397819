import { z } from './initialize';
import { getRules } from '.';
export const getContactSchema = () => {
    const rules = getRules();
    return z.object({
        name: rules.stringRequired.max(255),
        email: rules.user.email,
        subject: rules.stringRequired.max(255),
        message: rules.stringRequired.max(500),
        organization_id: rules.stringRequired,
    });
};
