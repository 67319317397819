import { zodResolver } from '@hookform/resolvers/zod';
import { useSearchParams } from 'next/navigation';
import { useForm } from 'react-hook-form';
import { confirmPasswordReset, signInWithEmailAndPassword } from '@atnd/firebase';
import { getUserSchema, z } from '../../../validation';
import { useSentry } from '../../error';
import { useSnackbar } from '../../notification/useSnackbar';
export const useResetPassword = (onSuccess) => {
    const { pushErrorToSentry } = useSentry();
    const { invalidNotification, openSnackbar } = useSnackbar();
    const searchParams = useSearchParams();
    const userSchema = getUserSchema();
    // TODO: i18n対応
    const { control, handleSubmit } = useForm({
        resolver: zodResolver(userSchema.pick({ password: true, passwordConfirmation: true }).superRefine((data, ctx) => {
            if (data.password !== data.passwordConfirmation) {
                ctx.addIssue({
                    path: ['passwordConfirmation'],
                    code: z.ZodIssueCode.custom,
                    message: 'パスワードが一致しません',
                });
            }
        })),
        defaultValues: {
            password: '',
            passwordConfirmation: '',
        },
    });
    const submit = (email) => handleSubmit(async (data) => {
        const oobCode = searchParams.get('oobCode');
        try {
            if (!oobCode)
                return;
            await confirmPasswordReset(oobCode, data.password);
            openSnackbar('パスワードの変更が完了しました', 'success');
            onSuccess();
        }
        catch (error) {
            openSnackbar('パスワードの変更に失敗しました', 'error');
            pushErrorToSentry({
                category: 'Authentication',
                payload: { hasOobCode: !!oobCode },
                error,
                message: 'パスワードの変更に失敗しました',
            });
        }
        if (!email)
            return;
        await signInWithEmailAndPassword(email, data.password);
    }, (error) => {
        console.error(error);
        invalidNotification();
    })();
    return {
        submit,
        control,
    };
};
