import { isArray, isNumber, isString } from 'remeda'

export const parseBoolean = (value: string): boolean | string => {
	switch (value.toLowerCase().trim()) {
		case 'true':
			return true
		case 'false':
			return false
		default:
			return value
	}
}

type Options<T extends Record<string, unknown>, U> = {
	processors?: {
		[K in keyof T]?: <I extends keyof U>(value: T[K]) => U[I]
	}
}

export const createConverter = <T extends Record<string, unknown>, U>(
	excludes: readonly (keyof T)[],
	options?: Options<T, U>,
): ((data: T) => U) => {
	return (data: T) => {
		const result = {} as U
		for (const prop of Object.entries(data)) {
			const key = prop[0] as keyof T
			const value = prop[1] as T[keyof T]

			if (!excludes.includes(key)) {
				const processors = options?.processors
				if (processors) {
					const processedValue = processors?.[key] ? processors[key]?.(value) : value

					if (!processedValue) continue

					result[key as keyof U] = processedValue as unknown as U[keyof U]
				} else {
					result[key as keyof U] = value as unknown as U[keyof U]
				}
			}
		}
		return result
	}
}

export const transformNumber = (value: unknown): number | undefined => {
	const num = Number(value)
	if (isNumber(num)) {
		return num
	}
	return undefined
}

export const transformNumberArray = (value: unknown): number[] | undefined => {
	if (isArray(value)) {
		return value.map((v) => Number(v)).filter((v) => isNumber(v))
	}
	return undefined
}

export const transformString = (value: unknown): string | undefined => {
	if (isString(value)) {
		return value
	}
	return undefined
}

export const transformStringArray = (value: unknown): string[] | undefined => {
	if (isArray(value)) {
		return value.map((v) => String(v)).filter((v) => isString(v))
	}
	return undefined
}

export const convertToHalfWidth = (str: string): string => {
	return str
		.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
			return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
		})
		.replace(/[！-～]/g, (s) => {
			return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
		})
}

export const getFilterParamsFromHash = (excludeKeys?: string[]) => {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const result: { [key: string]: any } = {}
	const hash = window.location.hash

	if (!hash) return result
	const decodedHash = decodeURIComponent(hash)
	const keyValuePairs = decodedHash.substring(1).split('&')

	for (const pair of keyValuePairs) {
		const [key, value] = pair.split('=')

		if (excludeKeys?.includes(key ?? '')) continue
		const normalizedValue = decodeURIComponent(value?.replace(/\+/g, ' ') ?? '')

		try {
			const parsedValue = JSON.parse(normalizedValue)

			const isEmptyArray = Array.isArray(parsedValue) && parsedValue.length === 0
			if (isEmptyArray) continue

			result[key as keyof typeof result] = parsedValue
		} catch (error) {
			console.error('getFilterParamsFromHash:', error)
			result[key as keyof typeof result] = normalizedValue
		}
	}

	return result
}
