"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const aspida_1 = require("aspida");
const api = ({ baseURL, fetch }) => {
    const prefix = (baseURL === undefined ? 'https://atndhosp.net/api/v0' : baseURL).replace(/\/$/, '');
    const PATH0 = '/categories';
    const PATH1 = '/change-email';
    const PATH2 = '/chief-complaints';
    const PATH3 = '/companies';
    const PATH4 = '/contacts';
    const PATH5 = '/hospitals';
    const PATH6 = '/inquiries';
    const PATH7 = '/inquiry-choices';
    const PATH8 = '/laboratory';
    const PATH9 = '/researches';
    const PATH10 = '/list-available-chief-complaints';
    const PATH11 = '/list-available-inquiries';
    const PATH12 = '/list-hospitals-of-organization';
    const PATH13 = '/list-reservations-by-date';
    const PATH14 = '/list-reservations-by-week';
    const PATH15 = '/list-reservations-of-user';
    const PATH16 = '/list-slots';
    const PATH17 = '/login';
    const PATH18 = '/login-staff';
    const PATH19 = '/new-users';
    const PATH20 = '/operation-hours';
    const PATH21 = '/operation-hours/edit';
    const PATH22 = '/operation-schedules';
    const PATH23 = '/organizations';
    const PATH24 = '/patient-search';
    const PATH25 = '/refresh-staff-token';
    const PATH26 = '/research';
    const PATH27 = '/survey';
    const PATH28 = '/survey-questions';
    const PATH29 = '/survey-section';
    const PATH30 = '/survey-statistics';
    const PATH31 = '/survey-sections';
    const PATH32 = '/survey-user-count';
    const PATH33 = '/survey-graph';
    const PATH34 = '/surveys';
    const PATH35 = '/research-consent';
    const PATH36 = '/reservation-counts';
    const PATH37 = '/reservations';
    const PATH38 = '/reset-password';
    const PATH39 = '/revert-status';
    const PATH40 = '/show-detailed-inquiries';
    const PATH41 = '/staffs';
    const PATH42 = '/statuses';
    const PATH43 = '/survey-answers';
    const PATH44 = '/survey-contacts';
    const PATH45 = '/survey-user';
    const PATH46 = '/user-hospital-relations';
    const PATH47 = '/user-organization-relations';
    const PATH48 = '/users';
    const PATH49 = '/verify-email';
    const PATH50 = '/vitals';
    const GET = 'GET';
    const POST = 'POST';
    const DELETE = 'DELETE';
    const PATCH = 'PATCH';
    return {
        categories: {
            _id: (val1) => {
                const prefix1 = `${PATH0}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            get: (option) => fetch(prefix, PATH0, GET, option).json(),
            $get: (option) => fetch(prefix, PATH0, GET, option).json().then(r => r.body),
            post: (option) => fetch(prefix, PATH0, POST, option).json(),
            $post: (option) => fetch(prefix, PATH0, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH0}`,
        },
        change_email: {
            post: (option) => fetch(prefix, PATH1, POST, option).send(),
            $post: (option) => fetch(prefix, PATH1, POST, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH1}`,
        },
        chief_complaints: {
            _id: (val1) => {
                const prefix1 = `${PATH2}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            get: (option) => fetch(prefix, PATH2, GET, option).json(),
            $get: (option) => fetch(prefix, PATH2, GET, option).json().then(r => r.body),
            post: (option) => fetch(prefix, PATH2, POST, option).json(),
            $post: (option) => fetch(prefix, PATH2, POST, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH2}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        companies: {
            /**
             * 会社の一覧を取得
             * @returns 会社の一覧を取得
             */
            get: (option) => fetch(prefix, PATH3, GET, option).json(),
            /**
             * 会社の一覧を取得
             * @returns 会社の一覧を取得
             */
            $get: (option) => fetch(prefix, PATH3, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH3}`,
        },
        contacts: {
            /**
             * ViewSet of Contact
             */
            post: (option) => fetch(prefix, PATH4, POST, option).send(),
            /**
             * ViewSet of Contact
             */
            $post: (option) => fetch(prefix, PATH4, POST, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH4}`,
        },
        hospitals: {
            _id: (val1) => {
                const prefix1 = `${PATH5}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            get: (option) => fetch(prefix, PATH5, GET, option).json(),
            $get: (option) => fetch(prefix, PATH5, GET, option).json().then(r => r.body),
            post: (option) => fetch(prefix, PATH5, POST, option).json(),
            $post: (option) => fetch(prefix, PATH5, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH5}`,
        },
        inquiries: {
            _id: (val1) => {
                const prefix1 = `${PATH6}/${val1}`;
                return {
                    /**
                     * ViewSet of Inquiry
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * ViewSet of Inquiry
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            post: (option) => fetch(prefix, PATH6, POST, option).json(),
            $post: (option) => fetch(prefix, PATH6, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH6}`,
        },
        inquiry_choices: {
            _id: (val1) => {
                const prefix1 = `${PATH7}/${val1}`;
                return {
                    /**
                     * ViewSet of Inquiry Choices
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * ViewSet of Inquiry Choices
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            get: (option) => fetch(prefix, PATH7, GET, option).json(),
            $get: (option) => fetch(prefix, PATH7, GET, option).json().then(r => r.body),
            post: (option) => fetch(prefix, PATH7, POST, option, 'FormData').json(),
            $post: (option) => fetch(prefix, PATH7, POST, option, 'FormData').json().then(r => r.body),
            $path: () => `${prefix}${PATH7}`,
        },
        laboratory: {
            _laboratory_alias: (val1) => {
                const prefix1 = `${PATH8}/${val1}`;
                return {
                    researches: {
                        /**
                         * ViewSet of Research
                         */
                        get: (option) => fetch(prefix, `${prefix1}${PATH9}`, GET, option).json(),
                        /**
                         * ViewSet of Research
                         */
                        $get: (option) => fetch(prefix, `${prefix1}${PATH9}`, GET, option).json().then(r => r.body),
                        $path: (option) => `${prefix}${prefix1}${PATH9}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                    },
                };
            },
        },
        list_available_chief_complaints: {
            _url_alias: (val1) => {
                const prefix1 = `${PATH10}/${val1}`;
                return {
                    /**
                     * Return available Categories/C.C.s for a Hospital
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * Return available Categories/C.C.s for a Hospital
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        list_available_inquiries: {
            _url_alias: (val1) => {
                const prefix1 = `${PATH11}/${val1}`;
                return {
                    _ccid: (val2) => {
                        const prefix2 = `${prefix1}/${val2}`;
                        return {
                            /**
                             * Return available inquiries for a Hospital
                             */
                            get: (option) => fetch(prefix, prefix2, GET, option).json(),
                            /**
                             * Return available inquiries for a Hospital
                             */
                            $get: (option) => fetch(prefix, prefix2, GET, option).json().then(r => r.body),
                            $path: (option) => `${prefix}${prefix2}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                        };
                    },
                };
            },
        },
        list_hospitals_of_organization: {
            _oailias: (val1) => {
                const prefix1 = `${PATH12}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        list_reservations_by_date: {
            _date: (val1) => {
                const prefix1 = `${PATH13}/${val1}`;
                return {
                    /**
                     * Return a list of reservations of a certain date
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * Return a list of reservations of a certain date
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        list_reservations_by_week: {
            _date: (val1) => {
                const prefix1 = `${PATH14}/${val1}`;
                return {
                    /**
                     * Return a list of reservations of a certain week
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * Return a list of reservations of a certain week
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        list_reservations_of_user: {
            _id: (val1) => {
                const prefix1 = `${PATH15}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    $path: (option) => `${prefix}${prefix1}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                };
            },
        },
        list_slots: {
            _url_alias: (val1) => {
                const prefix1 = `${PATH16}/${val1}`;
                return {
                    _ccid: (val2) => {
                        const prefix2 = `${prefix1}/${val2}`;
                        return {
                            _date: (val3) => {
                                const prefix3 = `${prefix2}/${val3}`;
                                return {
                                    _mode: (val4) => {
                                        const prefix4 = `${prefix3}/${val4}`;
                                        return {
                                            /**
                                             * Return reservation slots between start date and end date.
                                             */
                                            get: (option) => fetch(prefix, prefix4, GET, option).json(),
                                            /**
                                             * Return reservation slots between start date and end date.
                                             */
                                            $get: (option) => fetch(prefix, prefix4, GET, option).json().then(r => r.body),
                                            $path: () => `${prefix}${prefix4}`,
                                        };
                                    },
                                };
                            },
                        };
                    },
                };
            },
        },
        login: {
            /**
             * @returns ログイン処理(ユーザ)
             */
            get: (option) => fetch(prefix, PATH17, GET, option).json(),
            /**
             * @returns ログイン処理(ユーザ)
             */
            $get: (option) => fetch(prefix, PATH17, GET, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH17}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        login_staff: {
            post: (option) => fetch(prefix, PATH18, POST, option).json(),
            $post: (option) => fetch(prefix, PATH18, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH18}`,
        },
        new_users: {
            /**
             * 当日に作成し、かつ予約がないユーザーの一覧を取得
             */
            get: (option) => fetch(prefix, PATH19, GET, option).json(),
            /**
             * 当日に作成し、かつ予約がないユーザーの一覧を取得
             */
            $get: (option) => fetch(prefix, PATH19, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH19}`,
        },
        operation_hours: {
            _id: (val1) => {
                const prefix1 = `${PATH20}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: (option) => `${prefix}${prefix1}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                };
            },
            edit: {
                /**
                 * @returns 編集が成功した場合、200が返ります。
                 */
                post: (option) => fetch(prefix, PATH21, POST, option).json(),
                /**
                 * @returns 編集が成功した場合、200が返ります。
                 */
                $post: (option) => fetch(prefix, PATH21, POST, option).json().then(r => r.body),
                $path: (option) => `${prefix}${PATH21}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
            },
            get: (option) => fetch(prefix, PATH20, GET, option).json(),
            $get: (option) => fetch(prefix, PATH20, GET, option).json().then(r => r.body),
            post: (option) => fetch(prefix, PATH20, POST, option).json(),
            $post: (option) => fetch(prefix, PATH20, POST, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH20}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        operation_schedules: {
            _id: (val1) => {
                const prefix1 = `${PATH22}/${val1}`;
                return {
                    /**
                     * @returns 予約スケジュールの詳細を取得
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * @returns 予約スケジュールの詳細を取得
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * @returns 予約スケジュールの更新
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * @returns 予約スケジュールの更新
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: (option) => `${prefix}${prefix1}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                };
            },
            /**
             * @returns 予約スケジュールの一覧を取得
             */
            get: (option) => fetch(prefix, PATH22, GET, option).json(),
            /**
             * @returns 予約スケジュールの一覧を取得
             */
            $get: (option) => fetch(prefix, PATH22, GET, option).json().then(r => r.body),
            /**
             * @returns 予約スケジュールの作成
             */
            post: (option) => fetch(prefix, PATH22, POST, option).json(),
            /**
             * @returns 予約スケジュールの作成
             */
            $post: (option) => fetch(prefix, PATH22, POST, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH22}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        organizations: {
            get: (option) => fetch(prefix, PATH23, GET, option).json(),
            $get: (option) => fetch(prefix, PATH23, GET, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH23}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        patient_search: {
            _hospital_id: (val1) => {
                const prefix1 = `${PATH24}/${val1}`;
                return {
                    _patient_id: (val2) => {
                        const prefix2 = `${prefix1}/${val2}`;
                        return {
                            get: (option) => fetch(prefix, prefix2, GET, option).json(),
                            $get: (option) => fetch(prefix, prefix2, GET, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix2}`,
                        };
                    },
                };
            },
            get: (option) => fetch(prefix, PATH24, GET, option).json(),
            $get: (option) => fetch(prefix, PATH24, GET, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH24}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        refresh_staff_token: {
            post: (option) => fetch(prefix, PATH25, POST, option).json(),
            $post: (option) => fetch(prefix, PATH25, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH25}`,
        },
        research: {
            _research_alias: (val1) => {
                const prefix1 = `${PATH26}/${val1}`;
                return {
                    survey: {
                        _survey_alias: (val3) => {
                            const prefix3 = `${prefix1}${PATH27}/${val3}`;
                            return {
                                survey_questions: {
                                    /**
                                     * 研究用の設問の一覧を取得
                                     */
                                    get: (option) => fetch(prefix, `${prefix3}${PATH28}`, GET, option).json(),
                                    /**
                                     * 研究用の設問の一覧を取得
                                     */
                                    $get: (option) => fetch(prefix, `${prefix3}${PATH28}`, GET, option).json().then(r => r.body),
                                    $path: (option) => `${prefix}${prefix3}${PATH28}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                                },
                                survey_section: {
                                    _survey_section_alias: (val5) => {
                                        const prefix5 = `${prefix3}${PATH29}/${val5}`;
                                        return {
                                            survey_statistics: {
                                                /**
                                                 * 設問の統計の一覧を取得
                                                 * @returns research_alias&survey_alias&survey_section_aliasに紐づく設問ごとの選択問題の回答割合、数値回答問題の平均値を取得
                                                 */
                                                get: (option) => fetch(prefix, `${prefix5}${PATH30}`, GET, option).json(),
                                                /**
                                                 * 設問の統計の一覧を取得
                                                 * @returns research_alias&survey_alias&survey_section_aliasに紐づく設問ごとの選択問題の回答割合、数値回答問題の平均値を取得
                                                 */
                                                $get: (option) => fetch(prefix, `${prefix5}${PATH30}`, GET, option).json().then(r => r.body),
                                                $path: (option) => `${prefix}${prefix5}${PATH30}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                                            },
                                        };
                                    },
                                },
                                survey_sections: {
                                    /**
                                     * セクションの一覧を取得
                                     * @returns セクションの一覧
                                     */
                                    get: (option) => fetch(prefix, `${prefix3}${PATH31}`, GET, option).json(),
                                    /**
                                     * セクションの一覧を取得
                                     * @returns セクションの一覧
                                     */
                                    $get: (option) => fetch(prefix, `${prefix3}${PATH31}`, GET, option).json().then(r => r.body),
                                    $path: (option) => `${prefix}${prefix3}${PATH31}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                                },
                                survey_user_count: {
                                    /**
                                     * 研究用の設問の回答者数を取得
                                     * @returns 研究用の設問の回答者数を取得
                                     */
                                    get: (option) => fetch(prefix, `${prefix3}${PATH32}`, GET, option).json(),
                                    /**
                                     * 研究用の設問の回答者数を取得
                                     * @returns 研究用の設問の回答者数を取得
                                     */
                                    $get: (option) => fetch(prefix, `${prefix3}${PATH32}`, GET, option).json().then(r => r.body),
                                    $path: (option) => `${prefix}${prefix3}${PATH32}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                                },
                            };
                        },
                    },
                    survey_graph: {
                        _question_alias: (val3) => {
                            const prefix3 = `${prefix1}${PATH33}/${val3}`;
                            return {
                                /**
                                 * グラフデータを取得
                                 * @returns 設問ごとの回答割合、平均値を取得
                                 */
                                get: (option) => fetch(prefix, prefix3, GET, option).json(),
                                /**
                                 * グラフデータを取得
                                 * @returns 設問ごとの回答割合、平均値を取得
                                 */
                                $get: (option) => fetch(prefix, prefix3, GET, option).json().then(r => r.body),
                                $path: () => `${prefix}${prefix3}`,
                            };
                        },
                    },
                    surveys: {
                        /**
                         * 調査の一覧を取得
                         */
                        get: (option) => fetch(prefix, `${prefix1}${PATH34}`, GET, option).json(),
                        /**
                         * 調査の一覧を取得
                         */
                        $get: (option) => fetch(prefix, `${prefix1}${PATH34}`, GET, option).json().then(r => r.body),
                        $path: (option) => `${prefix}${prefix1}${PATH34}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                    },
                };
            },
        },
        research_consent: {
            _research_consent_id: (val1) => {
                const prefix1 = `${PATH35}/${val1}`;
                return {
                    /**
                     * ViewSet of Research Consent
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * ViewSet of Research Consent
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * ViewSet of Research Consent
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * ViewSet of Research Consent
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            /**
             * ViewSet of Research Consent
             */
            post: (option) => fetch(prefix, PATH35, POST, option).json(),
            /**
             * ViewSet of Research Consent
             */
            $post: (option) => fetch(prefix, PATH35, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH35}`,
        },
        reservation_counts: {
            /**
             * @returns 予約数の一覧
             */
            get: (option) => fetch(prefix, PATH36, GET, option).json(),
            /**
             * @returns 予約数の一覧
             */
            $get: (option) => fetch(prefix, PATH36, GET, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH36}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        reservations: {
            _id: (val1) => {
                const prefix1 = `${PATH37}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            get: (option) => fetch(prefix, PATH37, GET, option).json(),
            $get: (option) => fetch(prefix, PATH37, GET, option).json().then(r => r.body),
            post: (option) => fetch(prefix, PATH37, POST, option).json(),
            $post: (option) => fetch(prefix, PATH37, POST, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH37}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        reset_password: {
            post: (option) => fetch(prefix, PATH38, POST, option).send(),
            $post: (option) => fetch(prefix, PATH38, POST, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH38}`,
        },
        revert_status: {
            _rid: (val1) => {
                const prefix1 = `${PATH39}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        show_detailed_inquiries: {
            _rid: (val1) => {
                const prefix1 = `${PATH40}/${val1}`;
                return {
                    /**
                     * Return detailed inquiries of a reservation
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * Return detailed inquiries of a reservation
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        staffs: {
            _id: (val1) => {
                const prefix1 = `${PATH41}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            get: (option) => fetch(prefix, PATH41, GET, option).json(),
            $get: (option) => fetch(prefix, PATH41, GET, option).json().then(r => r.body),
            post: (option) => fetch(prefix, PATH41, POST, option).json(),
            $post: (option) => fetch(prefix, PATH41, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH41}`,
        },
        statuses: {
            post: (option) => fetch(prefix, PATH42, POST, option).json(),
            $post: (option) => fetch(prefix, PATH42, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH42}`,
        },
        survey_answers: {
            _survey_answer_id: (val1) => {
                const prefix1 = `${PATH43}/${val1}`;
                return {
                    /**
                     * 研究用の設問の回答を取得
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * 研究用の設問の回答を取得
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * 研究用の設問の回答を更新
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * 研究用の設問の回答を更新
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        survey_contacts: {
            /**
             * 研究用の設問に関する問い合わせ用API
             */
            post: (option) => fetch(prefix, PATH44, POST, option).send(),
            /**
             * 研究用の設問に関する問い合わせ用API
             */
            $post: (option) => fetch(prefix, PATH44, POST, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH44}`,
        },
        survey_questions: {
            _question_id: (val1) => {
                const prefix1 = `${PATH28}/${val1}`;
                return {
                    /**
                     * 研究用の設問の詳細を取得
                     * @returns 設問の詳細を取得
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * 研究用の設問の詳細を取得
                     * @returns 設問の詳細を取得
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        survey_user: {
            _survey_user_id: (val1) => {
                const prefix1 = `${PATH45}/${val1}`;
                return {
                    survey_answers: {
                        /**
                         * 研究用の設問の回答を登録
                         * @returns 成功
                         */
                        post: (option) => fetch(prefix, `${prefix1}${PATH43}`, POST, option).json(),
                        /**
                         * 研究用の設問の回答を登録
                         * @returns 成功
                         */
                        $post: (option) => fetch(prefix, `${prefix1}${PATH43}`, POST, option).json().then(r => r.body),
                        /**
                         * 研究用の設問の回答を取得
                         */
                        get: (option) => fetch(prefix, `${prefix1}${PATH43}`, GET, option).json(),
                        /**
                         * 研究用の設問の回答を取得
                         */
                        $get: (option) => fetch(prefix, `${prefix1}${PATH43}`, GET, option).json().then(r => r.body),
                        $path: (option) => `${prefix}${prefix1}${PATH43}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                    },
                };
            },
            _survey_user_id_string: (val1) => {
                const prefix1 = `${PATH45}/${val1}`;
                return {
                    /**
                     * 研究参加者の詳細を取得
                     * @returns 研究参加者の詳細を取得
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * 研究参加者の詳細を取得
                     * @returns 研究参加者の詳細を取得
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * 研究参加者の更新
                     * @returns 研究参加者の更新
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * 研究参加者の更新
                     * @returns 研究参加者の更新
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            /**
             * 研究参加者の登録
             */
            post: (option) => fetch(prefix, PATH45, POST, option).json(),
            /**
             * 研究参加者の登録
             */
            $post: (option) => fetch(prefix, PATH45, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH45}`,
        },
        user_hospital_relations: {
            _id: (val1) => {
                const prefix1 = `${PATH46}/${val1}`;
                return {
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    /**
                     * ViewSet of User Hospital Relation
                     */
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            /**
             * ViewSet of User Hospital Relation
             */
            get: (option) => fetch(prefix, PATH46, GET, option).json(),
            /**
             * ViewSet of User Hospital Relation
             */
            $get: (option) => fetch(prefix, PATH46, GET, option).json().then(r => r.body),
            /**
             * ViewSet of User Hospital Relation
             */
            post: (option) => fetch(prefix, PATH46, POST, option).json(),
            /**
             * ViewSet of User Hospital Relation
             */
            $post: (option) => fetch(prefix, PATH46, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH46}`,
        },
        user_organization_relations: {
            _id: (val1) => {
                const prefix1 = `${PATH47}/${val1}`;
                return {
                    /**
                     * ViewSet of User Organization Relation
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * ViewSet of User Organization Relation
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * ViewSet of User Organization Relation
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * ViewSet of User Organization Relation
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        users: {
            _id: (val1) => {
                const prefix1 = `${PATH48}/${val1}`;
                return {
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            post: (option) => fetch(prefix, PATH48, POST, option).json(),
            $post: (option) => fetch(prefix, PATH48, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH48}`,
        },
        verify_email: {
            post: (option) => fetch(prefix, PATH49, POST, option).send(),
            $post: (option) => fetch(prefix, PATH49, POST, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH49}`,
        },
        vitals: {
            _id: (val1) => {
                const prefix1 = `${PATH50}/${val1}`;
                return {
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
    };
};
exports.default = api;
