import { z } from './initialize';
import { getRules } from '.';
export const getVitalSchema = () => {
    const rules = getRules();
    return z.object({
        id: rules.string,
        ht: rules.double.nullable(),
        bw: rules.double.nullable(),
        bt: rules.double.nullable(),
        s_bp_home: rules.number.nullable(),
        d_bp_home: rules.number.nullable(),
        s_bp_clinic: rules.number.nullable(),
        d_bp_clinic: rules.number.nullable(),
        hr: rules.number.nullable(),
        rr: rules.number.nullable(),
        sp02: rules.number.nullable(),
    });
};
