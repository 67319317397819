import { z } from './initialize';
import { getRules } from '.';
export const getUserSchema = () => {
    const rules = getRules();
    return z.object({
        email: rules.user.email.max(254),
        password: rules.user.password,
        passwordConfirmation: rules.user.password,
        first_name: rules.stringRequired.max(20),
        last_name: rules.stringRequired.max(20),
        first_name_kana: rules.stringRequired.max(20),
        last_name_kana: rules.stringRequired.max(20),
        birthday: rules.birthdayString,
        sex: rules.user.sex,
        has_allergy: rules.booleanRequired,
        allergy: rules.string.max(100).nullable(),
        phone_number: rules.stringRequired.max(20),
        postal_code: rules.address.postalCode.max(8),
        address_prefecture: rules.stringRequired.max(10),
        address_city: rules.stringRequired.max(100),
        address_street: rules.stringRequired.max(255),
        notification: rules.booleanRequired,
    });
};
export const getSigninSchema = () => {
    const rules = getRules();
    return z.object({
        email: rules.emailRequired,
        password: rules.user.password,
    });
};
export const getForgotPasswordSchema = () => {
    const rules = getRules();
    return z.object({
        email: rules.emailRequired,
    });
};
