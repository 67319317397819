"use strict";
/**
 * @file 日付計算関連のユーティリティ関数
 * @module utils/calculate
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isToday = exports.isSameTime = exports.truncateToQuarterHour = exports.calculateAge = exports.getDateDiffFromDay = exports.isOverlapDates = exports.calcDaysCountOfMonth = void 0;
const init_1 = require("../config/init");
/**
 * 指定された月の日数を計算する
 * @param {string} date - 対象の日付
 * @returns {number} 月の日数
 */
const calcDaysCountOfMonth = (date) => {
    const startDate = (0, init_1.appDayjs)(date).startOf('month');
    const endDate = (0, init_1.appDayjs)(date).endOf('month');
    const numberOfMonthDays = endDate.diff(startDate, 'day') + 1;
    return numberOfMonthDays;
};
exports.calcDaysCountOfMonth = calcDaysCountOfMonth;
/**
 * 2つの期間が重複しているかどうかを判定する
 * @param {string | Date} dateStart - 期間1の開始日
 * @param {string | Date} dateEnd - 期間1の終了日
 * @param {string | Date} comparisonDateStart - 期間2の開始日
 * @param {string | Date} comparisonDateEnd - 期間2の終了日
 * @returns {boolean} 期間が重複している場合はtrue
 */
const isOverlapDates = (dateStart, dateEnd, comparisonDateStart, comparisonDateEnd) => {
    const inputStart = (0, init_1.appDayjs)(dateStart);
    const inputEnd = (0, init_1.appDayjs)(dateEnd);
    const existingStart = (0, init_1.appDayjs)(comparisonDateStart);
    const existingEnd = (0, init_1.appDayjs)(comparisonDateEnd);
    return inputStart.isBefore(existingEnd) && existingStart.isBefore(inputEnd);
};
exports.isOverlapDates = isOverlapDates;
/**
 * 指定された日付と今日の日付の差分を日数で取得する
 * @param {string | Date} date - 比較する日付
 * @returns {number} 日数の差分
 */
const getDateDiffFromDay = (date) => {
    const today = (0, init_1.appDayjs)().startOf('day');
    const inputDate = (0, init_1.appDayjs)(date);
    const diff = inputDate.diff(today, 'day');
    return diff;
};
exports.getDateDiffFromDay = getDateDiffFromDay;
/**
 * 誕生日から年齢を計算する
 * @param {string} birthday - 誕生日
 * @returns {number} 年齢
 */
const calculateAge = (birthday) => {
    const today = (0, init_1.appDayjs)();
    const birth = (0, init_1.appDayjs)(birthday);
    const age = today.diff(birth, 'year');
    return age;
};
exports.calculateAge = calculateAge;
/**
 * 時刻を15分単位で切り捨てる
 * @param {string} [dateString] - 対象の日時文字列
 * @returns {string} 15分単位で切り捨てられた日時文字列
 */
const truncateToQuarterHour = (dateString) => {
    if (!dateString)
        return '';
    const date = (0, init_1.appDayjs)(dateString);
    const minutes = date.minute();
    const truncatedMinutes = Math.floor(minutes / 15) * 15;
    const truncatedDate = date.minute(truncatedMinutes).second(0).millisecond(0);
    return truncatedDate.format('YYYY-MM-DD HH:mm');
};
exports.truncateToQuarterHour = truncateToQuarterHour;
/**
 * 2つの日付の時刻が同じかどうかを判定する
 * @param {string} date1 - 比較する日付1
 * @param {string} date2 - 比較する日付2
 * @returns {boolean} 時刻が同じ場合はtrue
 */
const isSameTime = (date1, date2) => {
    const time1 = (0, init_1.appDayjs)(date1).format('HH:mm:ss');
    const time2 = (0, init_1.appDayjs)(date2).format('HH:mm:ss');
    return time1 === time2;
};
exports.isSameTime = isSameTime;
/**
 * 指定された日付が今日かどうかを判定する
 * @param {string} date - 判定する日付
 * @returns {boolean} 今日の場合はtrue
 */
const isToday = (date) => {
    const today = (0, init_1.appDayjs)().format('YYYY-MM-DD');
    return (0, init_1.appDayjs)(date).format('YYYY-MM-DD') === today;
};
exports.isToday = isToday;
