import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { api } from '@atnd/api-client';
import { getCurrentFirebaseUser, getEmailVerificationErrorMessage, verifyEmail } from '@atnd/firebase';
import { useSentry } from '../../error';
import { useSnackbar } from '../../notification/useSnackbar';
/**
 * メールアドレス認証に関連する機能を提供するカスタムフック
 *
 * @description
 * このフックは以下の機能を提供します：
 * - メール認証リンクの送信
 * - OobCodeを使用したメールアドレスの認証
 * - メール認証状態の確認
 * - Firebaseユーザー情報の更新
 *
 * @returns メール認証に関連する状態と関数
 */
export const useEmailVerification = () => {
    const { pushErrorToSentry } = useSentry();
    const searchParams = useSearchParams();
    const oobCode = searchParams.get('oobCode');
    const { notifyError, openSnackbar } = useSnackbar();
    const currentUser = getCurrentFirebaseUser();
    const [errorMessage, setErrorMessage] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    /**
     * メール認証リンクを送信し、結果を通知する
     *
     * @description
     * - ユーザーがログインしていない場合はエラーを表示
     * - すでに認証済みの場合はエラーを表示
     * - 認証メールの送信に成功した場合は成功メッセージを表示
     */
    const sendEmailVerificationAndNotify = async () => {
        if (!currentUser) {
            openSnackbar('ログインが必要です', 'error');
            return;
        }
        if (currentUser.emailVerified) {
            openSnackbar('このメールアドレスはすでに認証されています', 'error');
            return;
        }
        const payload = {
            email: currentUser.email ?? '',
        };
        try {
            await api.verify_email.$post({
                body: payload,
            });
            openSnackbar('メールを再送信しました', 'success');
        }
        catch (error) {
            notifyError(error);
            pushErrorToSentry({
                category: 'Authentication',
                error,
                message: 'メールの再送信に失敗しました',
            });
        }
    };
    /**
     * OobCodeを使用してメールアドレスを認証する
     *
     * @description
     * - すでに認証済みの場合は何もしない
     * - OobCodeが不正な場合はエラーを表示
     * - 認証に成功した場合は成功メッセージを表示
     */
    const verifyEmailByOobCode = async () => {
        if (emailVerified)
            return;
        await refreshFirebaseUser();
        const firebaseUser = getCurrentFirebaseUser();
        if (firebaseUser?.emailVerified)
            return;
        if (!oobCode) {
            openSnackbar('認証コードが不正です', 'error');
            return;
        }
        try {
            await verifyEmail(oobCode);
            setEmailVerified(true);
            openSnackbar('メールアドレスの認証が完了しました', 'success');
        }
        catch (error) {
            const message = getEmailVerificationErrorMessage(error);
            setEmailVerified(false);
            setErrorMessage(message);
            pushErrorToSentry({
                category: 'Authentication',
                payload: { firebaseUserId: firebaseUser?.uid },
                error,
                message,
            });
        }
    };
    /**
     * メール認証ページへの遷移前にメール認証状態を確認する
     *
     * @description
     * - ユーザーがログインしていない場合は何もしない
     * - メール認証が完了していない場合はエラーを表示
     */
    const handlePushToEmailVerificationPage = async () => {
        const firebaseUser = getCurrentFirebaseUser();
        if (!firebaseUser)
            return;
        await firebaseUser.reload();
        if (!firebaseUser.emailVerified) {
            openSnackbar('メールアドレスの認証が完了していません', 'error');
        }
    };
    /**
     * Firebaseユーザー情報を最新の状態に更新する
     */
    const refreshFirebaseUser = async () => {
        const firebaseUser = getCurrentFirebaseUser();
        if (firebaseUser) {
            await firebaseUser.reload();
        }
    };
    return {
        email: currentUser?.email,
        isReady: !!currentUser,
        handlePushToEmailVerificationPage,
        sendEmailVerificationAndNotify,
        verified: currentUser?.emailVerified || emailVerified,
        errorMessage,
        verifyEmailByOobCode,
        refreshFirebaseUser,
    };
};
