import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { getSignupErrorMessage } from '@atnd/firebase';
import { convertToHalfWidth } from '@atnd/utils';
import { isCreatingUserAtom } from '../stores/authStatusAtom';
import { createUserAtom, updateUserAtom, userAtom } from '../stores/userAtom';
import { api } from '@atnd/api-client';
import { formatDate } from '@atnd/dayjs';
import { getUserSchema } from '../../../validation';
import { Core } from '../../../yubinbango';
import { useSnackbar } from '../../notification/useSnackbar';
/**
 * ユーザーフォームのカスタムフック
 * @description ユーザーの新規登録・編集フォームの状態管理を行う
 */
export const useUserForm = ({ isEdit, onSubmit, shouldSendEmailVerification }) => {
    const { openSnackbar } = useSnackbar();
    const user = useAtomValue(userAtom);
    const [isCreatingUser, setIsCreatingUser] = useAtom(isCreatingUserAtom);
    const createUser = useSetAtom(createUserAtom);
    const updateUser = useSetAtom(updateUserAtom);
    const userSchema = getUserSchema();
    const { control, getValues, handleSubmit, reset, setValue, watch, formState } = useForm({
        mode: 'onSubmit',
        resolver: zodResolver(isEdit
            ? userSchema.omit({ password: true, passwordConfirmation: true })
            : userSchema.omit({ notification: true }).superRefine((data, ctx) => {
                if (data.password !== data.passwordConfirmation) {
                    ctx.addIssue({
                        path: ['passwordConfirmation'],
                        code: z.ZodIssueCode.custom,
                        message: 'パスワードが一致しません',
                    });
                }
            })),
        defaultValues: getInitialValue(user),
    });
    /**
     * ユーザーを新規作成する
     * @param organizationUrlAlias 組織のURLエイリアス
     */
    const handleCreateUser = (organizationUrlAlias) => {
        const birthday = getValues('birthday');
        if (birthday) {
            setValue('birthday', formatDate(birthday));
        }
        return handleSubmit(async ({ passwordConfirmation: _, ...payload }) => {
            setIsCreatingUser(true);
            try {
                const user = await createUser({ ...payload, organizationUrlAlias });
                if (shouldSendEmailVerification) {
                    await api.verify_email.$post({ body: { email: user.email } });
                }
                openSnackbar('登録が完了しました', 'success');
                onSubmit?.(user);
            }
            catch (error) {
                const message = getSignupErrorMessage(error);
                openSnackbar(message, 'error');
            }
            finally {
                setIsCreatingUser(false);
            }
        }, (errors) => {
            setIsCreatingUser(false);
            console.error(errors);
            openSnackbar('入力内容に誤りがあります', 'error');
        })();
    };
    /**
     * ユーザー情報を更新する
     */
    const handleUpdateUser = async () => {
        if (!user)
            throw new Error('未ログインのユーザーは更新できません');
        const birthday = getValues('birthday');
        if (birthday) {
            setValue('birthday', formatDate(birthday));
        }
        try {
            setIsCreatingUser(true);
            const payload = getValues();
            await updateUser({ id: user.id, ...payload });
            openSnackbar('ユーザー情報を更新しました', 'success');
            onSubmit?.();
        }
        catch (error) {
            if (error instanceof Error) {
                openSnackbar(error.message, 'error');
            }
        }
        finally {
            setIsCreatingUser(false);
        }
    };
    /**
     * 郵便番号から住所を自動入力する
     * @param postalCode 郵便番号
     */
    const onPostalCodeChange = async (postalCode) => {
        const core = new Core();
        const halfWidthPostalCode = convertToHalfWidth(postalCode.replace('ー', '-'));
        setValue('postal_code', halfWidthPostalCode);
        const hyphenRemoved = halfWidthPostalCode.replace('-', '');
        if (hyphenRemoved.length !== 7)
            return;
        const address = await core.fetchAddressByPostalCode(hyphenRemoved);
        setValue('address_prefecture', address.region);
        setValue('address_city', address.locality);
        setValue('address_street', address.street);
    };
    return {
        user,
        control,
        formState,
        handleCreateUser,
        handleUpdateUser,
        onPostalCodeChange,
        watch,
        reset,
        isLoading: isCreatingUser,
    };
};
const initialFormValues = {
    email: '',
    password: '',
    passwordConfirmation: '',
    last_name: '',
    first_name: '',
    last_name_kana: null,
    first_name_kana: null,
    birthday: null,
    sex: 'M',
    phone_number: null,
    postal_code: null,
    address_prefecture: null,
    address_city: null,
    address_street: null,
    has_allergy: false,
    allergy: null,
    notification: true,
};
const getInitialValue = (user) => {
    if (!user)
        return initialFormValues;
    return {
        ...initialFormValues,
        email: user.email,
        last_name: user.last_name,
        first_name: user.first_name,
        last_name_kana: user.last_name_kana,
        first_name_kana: user.first_name_kana,
        birthday: user.birthday,
        postal_code: user.postal_code,
        address_prefecture: user.address_prefecture,
        address_city: user.address_city,
        address_street: user.address_street,
        phone_number: user.phone_number,
        sex: user.sex,
        has_allergy: user.has_allergy,
        allergy: user.allergy,
        current_hospital_id: user.current_hospital_id,
        default_hospital_id: user.default_hospital_id,
        notification: user.notification ?? true,
    };
};
