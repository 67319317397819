import { z } from './initialize';
import { getRules } from '.';
export const getNextReservationSchema = () => {
    const rules = getRules();
    return z.object({
        chiefComplaint: rules.stringRequired,
        estimatedTime: rules.numberRequired,
        reservation_date: rules.stringRequired,
        clinicAlias: rules.stringRequired,
    });
};
