import { appDayjs } from '@atnd/dayjs';
import { z } from './initialize';
export const getRules = (lang = 'ja') => {
    const isJa = lang === 'ja';
    const requiredMessage = isJa ? '必須項目です' : 'This field is required';
    const invalidTypeMessage = isJa ? '入力値の形式が正しくありません' : 'Invalid input format';
    const intValidation = z.number().int(isJa ? '整数で入力してください' : 'Enter an integer');
    const emailRegex = /^[A-Za-z0-9]+[A-Za-z0-9_.+-]*@[A-Za-z0-9_.-]+\.[A-Za-z]{2,}$/;
    const emailValidation = z
        .string()
        .min(1, requiredMessage)
        .trim()
        .regex(emailRegex, isJa ? 'メールアドレスを入力してください' : 'Enter an email address');
    return {
        string: z.string().trim(),
        stringRequired: z.string().trim().min(1, requiredMessage),
        emailRequired: emailValidation,
        number: intValidation.nullable(),
        numberRequired: intValidation,
        positiveNumber: intValidation.positive(),
        double: z.number().nullable(),
        doubleRequired: z.number(),
        positiveDouble: z.number().positive(),
        booleanRequired: z.boolean({
            required_error: requiredMessage,
            invalid_type_error: requiredMessage,
        }),
        selectNumberRequired: z.number().positive(requiredMessage),
        date: z.date(),
        dateRequired: z.date({
            required_error: requiredMessage,
            invalid_type_error: invalidTypeMessage,
        }),
        birthdayString: z.string().refine((value) => {
            return appDayjs(value).isValid();
        }, isJa ? 'YYYY/MM/DDの形式で入力してください' : 'Enter a date in YYYY/MM/DD format'),
        url: z.string().url(),
        memo: z.string().trim().max(500, '500文字以内で入力してください').nullable(),
        memoRequired: z.string().trim().max(500, '500文字以内で入力してください').nonempty('必須項目です'),
        user: {
            sex: z.union([z.literal('M'), z.literal('F')]),
            phoneNumber: z
                .string()
                .trim()
                .regex(/^\d{3}-\d{4}-\d{4}$/, isJa ? '電話番号の形式で入力してください' : 'Invalid phone number format'),
            email: emailValidation,
            password: z
                .string()
                .regex(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d\-^$*.[\]{}()?"!@#%&\/\\,><':;|_~`]{8,}$/, isJa
                ? '英字・数字両方を含め8文字以上で入力してください'
                : 'Enter 8 characters or more including both letters and numbers'),
        },
        address: {
            postalCode: z
                .string()
                .trim()
                .min(7)
                .max(8)
                .regex(/^\d{3}[ー-]?\d{4}$/, isJa ? '郵便番号の形式に誤りがあります' : 'Invalid postal code format'),
            prefecture: z.string().trim().max(10).nonempty(requiredMessage),
            city: z.string().trim().max(100).nonempty(requiredMessage),
            street: z.string().trim().max(100).nonempty(requiredMessage),
        },
    };
};
