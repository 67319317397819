import { destroyCookie, setCookie as nookiesSetCookie, parseCookies } from 'nookies'

const initialOptions: {
	path?: string
	maxAge?: number
} = {
	maxAge: 1 * 24 * 60 * 60, // 1 day
	path: '/',
}

export const setCookie = (key: string, value: string, options = initialOptions) => {
	nookiesSetCookie(null, key, value, options)
}

export const getCookie = (key: string) => {
	return parseCookies()[key]
}

export const removeCookie = (key: string) => {
	destroyCookie(null, key, {
		path: '/',
	})
}

export const setHospitalIdToCookie = (clinicAlias: string, hospital_id: string) => {
	setCookie(`${clinicAlias}.hospital_id`, hospital_id, {
		path: '/',
	})
}

export const getHospitalIdFromCookie = (clinicAlias: string | undefined) => {
	if (!clinicAlias) return
	return getCookie(`${clinicAlias}.hospital_id`)
}

export const setClinicAliasToCookie = (clinic_alias: string) => {
	setCookie('clinic_alias', clinic_alias, {
		path: '/',
	})
}

export const setFirebaseIdToken = (token: string) => {
	setCookie('token', token, {
		maxAge: 60 * 60, // 1hour
		path: '/',
	})
}

export const getFirebaseIdToken = () => {
	return getCookie('token')
}

const HOSPITAL_ACCESS_TOKEN_KEY = 'hospital_access_token'
export const hospitalAccessToken = {
	get: () => getCookie(HOSPITAL_ACCESS_TOKEN_KEY),
	set: (token: string) => setCookie(HOSPITAL_ACCESS_TOKEN_KEY, token),
	remove: () => removeCookie(HOSPITAL_ACCESS_TOKEN_KEY),
}

const HOSPITAL_REFRESH_TOKEN_KEY = 'hospital_refresh_token'
export const hospitalRefreshToken = {
	get: () => getCookie(HOSPITAL_REFRESH_TOKEN_KEY),
	set: (token: string) => setCookie(HOSPITAL_REFRESH_TOKEN_KEY, token),
	remove: () => removeCookie(HOSPITAL_REFRESH_TOKEN_KEY),
}
