import Zod from 'zod';
const errMap = (issue, _ctx) => {
    const handleError = (issue) => {
        switch (issue.code) {
            case Zod.ZodIssueCode.invalid_type:
                return getInvalidTypeMessage(issue);
            case Zod.ZodIssueCode.invalid_date:
                return `日付の形式が正しくありません`;
            case Zod.ZodIssueCode.too_small:
                return getTooSmallMessage(issue);
            case Zod.ZodIssueCode.too_big:
                return getTooBigMessage(issue);
            case Zod.ZodIssueCode.invalid_string:
                return `文字列を入力してください`;
            default:
                return `入力内容が正しくありません`;
        }
    };
    const message = handleError(issue);
    return {
        message,
        path: issue.path,
    };
};
const getInvalidTypeMessage = (issue) => {
    switch (issue.received) {
        case 'undefined':
        case 'null':
            return '必須項目です';
        case 'string':
            return issue.expected === 'number' ? '必須項目です' : '文字を入力してください';
        default:
            return `${issue.expected}を期待しましたが, ${issue.received}が入力されました`;
    }
};
const getTooBigMessage = (issue) => {
    switch (issue.type) {
        case 'string':
            return `${issue.maximum}文字以下で入力してください`;
        case 'number':
            return `${issue.maximum}以下の値を入力してください`;
        default:
            return `入力に誤りがあります`;
    }
};
const getTooSmallMessage = (issue) => {
    switch (issue.type) {
        case 'string':
            return `${issue.minimum}文字以上で入力してください`;
        case 'number':
            return `${issue.minimum}以上の値を入力してください`;
        default:
            return `入力に誤りがあります`;
    }
};
Zod.setErrorMap(errMap);
export { Zod as z };
