import { z } from './initialize';
import { getRules } from '.';
export const getHospitalSchema = () => {
    const rules = getRules();
    return z.object({
        name: rules.string,
        name_kana: rules.string,
        email: rules.emailRequired,
        phone_number: rules.string,
        postal_code: rules.address.postalCode,
        address_prefecture: rules.address.prefecture,
        address_city: rules.address.city,
        address_street: rules.address.street,
        contact_url: rules.string,
        time_unit: rules.numberRequired,
    });
};
