"use strict";
/**
 * @file カレンダー関連のユーティリティ関数
 * @module utils/calendar
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWeekdays = exports.parseCalendarDate = void 0;
const init_1 = require("../config/init");
/**
 * 日付を各種フォーマットに変換する
 * @param {string | Date} date - 変換する日付
 * @returns {{ day: string, date: string, MMDD: string, weekday: string }} 変換された日付情報
 */
const parseCalendarDate = (date) => {
    const day = (0, init_1.appDayjs)(date);
    return {
        day: day.format('D'),
        date: day.format('YYYY-MM-DD'),
        MMDD: day.format('MM/DD'),
        weekday: day.format('(ddd)'),
    };
};
exports.parseCalendarDate = parseCalendarDate;
/**
 * 指定された日付から1週間分の日付データを生成する
 * @param {string | Date} baseDate - 基準となる日付
 * @returns {WeekdaysData} 1週間分の日付データ
 */
const getWeekdays = (baseDate) => {
    const date = (0, init_1.appDayjs)(baseDate);
    const weekdays = Array.from({ length: 7 }, (_, i) => {
        const day = date.add(i, 'day');
        return {
            day: day.format('D'),
            date: day.format('YYYY-MM-DD'),
            MMDD: day.format('MM/DD'),
            weekday: day.format('(ddd)'),
        };
    });
    return {
        startAt: weekdays[0]?.date ?? '',
        endAt: weekdays[6]?.date ?? '',
        weekdays,
    };
};
exports.getWeekdays = getWeekdays;
