import { difference, isPlainObject, keys } from 'remeda'

// オブジェクトの差分を取得する関数
const getObjectDiff = <T extends Record<string, unknown>>(original: T, updated: T): Partial<T> => {
	const diff: Partial<T> = {}
	for (const key in updated) {
		if (original[key] !== updated[key]) {
			if (isPlainObject(original[key]) && isPlainObject(updated[key])) {
				const nestedDiff = getObjectDiff(original[key], updated[key])
				const isEmptyObject = !keys(nestedDiff).length
				if (!isEmptyObject) {
					diff[key] = nestedDiff as T[Extract<keyof T, string>]
				}
			} else {
				diff[key] = updated[key]
			}
		}
	}

	return diff
}

// 差分のみをフィルタリングする関数
export const extractDiff = <T>(originalData: T, updatedData: T): T => {
	if (originalData === null || updatedData === null) return updatedData

	if (Array.isArray(originalData) && Array.isArray(updatedData)) {
		return difference.multiset(updatedData, originalData) as T
	}
	if (originalData && typeof originalData === 'object' && updatedData && typeof updatedData === 'object') {
		return getObjectDiff(originalData as Record<string, unknown>, updatedData as Record<string, unknown>) as T
	}

	return updatedData
}
