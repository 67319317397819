"use strict";
/**
 * @file 日付フォーマット関連のユーティリティ関数
 * @module utils/format
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.separateDateAndTime = exports.minutesToTime = exports.formatDate = void 0;
const init_1 = require("../config/init");
/**
 * 日付を指定されたフォーマットに変換する
 * @param {Date | Dayjs | string | number} [date] - 変換する日付
 * @param {string} [type] - フォーマットタイプ
 * @returns {string} フォーマットされた日付文字列
 */
const formatDate = (date, type) => {
    if (!date)
        return '';
    if (type === 'YYYY-MM-DD HH:mm') {
        return (0, init_1.appDayjs)(date).format('YYYY-MM-DD HH:mm');
    }
    if (type === 'YYYY/MM/DD') {
        return (0, init_1.appDayjs)(date).format('YYYY/MM/DD');
    }
    if (type === 'YYYY/MM/DD HH:mm') {
        return (0, init_1.appDayjs)(date).format('YYYY/MM/DD HH:mm');
    }
    if (type === 'MMDD') {
        return (0, init_1.appDayjs)(date).format('MM/DD');
    }
    if (type === 'MM/DD(ddd)') {
        return (0, init_1.appDayjs)(date).format('MM/DD(ddd)');
    }
    if (type === 'MM/DD(ddd) HH:mm') {
        return (0, init_1.appDayjs)(date).format('MM/DD(ddd) HH:mm');
    }
    if (type === 'YYYY/MM/DD(ddd) HH:mm') {
        return (0, init_1.appDayjs)(date).format('YYYY/MM/DD(ddd) HH:mm');
    }
    if (type === 'HH:mm') {
        return (0, init_1.appDayjs)(date).format('HH:mm');
    }
    if (type === 'D') {
        return (0, init_1.appDayjs)(date).format('D');
    }
    return (0, init_1.appDayjs)(date).format('YYYY-MM-DD');
};
exports.formatDate = formatDate;
/**
 * 分を時刻形式（HH:mm）に変換する
 * @param {number} minutes - 変換する分数
 * @returns {string} HH:mm形式の時刻文字列
 */
const minutesToTime = (minutes) => {
    const day = (0, init_1.appDayjs)().startOf('day');
    const time = day.add(minutes, 'minute');
    return time.format('HH:mm');
};
exports.minutesToTime = minutesToTime;
/**
 * 日付文字列を年、月、日、時刻に分割する
 * @param {string} [date] - 分割する日付文字列
 * @returns {{ year: string, month: string, day: string, time: string }} 分割された日付情報
 */
const separateDateAndTime = (date) => {
    if (!date)
        return {
            year: '',
            month: '',
            day: '',
            time: '',
        };
    const datetime = (0, init_1.appDayjs)(date).format('YYYYMMDD HH:mm');
    const year = datetime.slice(0, 4);
    const month = datetime.slice(4, 6);
    const day = datetime.slice(6, 8);
    const time = datetime.slice(9, 14);
    return {
        year,
        month,
        day,
        time,
    };
};
exports.separateDateAndTime = separateDateAndTime;
