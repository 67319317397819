"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFirebaseError = exports.getEmailVerificationErrorMessage = exports.getUpdateEmailErrorMessage = exports.getLoginErrorMessage = exports.getSignupErrorMessage = void 0;
const app_1 = require("firebase/app");
const getSignupErrorMessage = (error) => {
    if (error instanceof app_1.FirebaseError) {
        switch (error.code) {
            case 'auth/email-already-in-use':
                return 'このメールアドレスは既に使用されています';
            case 'auth/invalid-email':
                return 'メールアドレスの形式が正しくありません';
            case 'auth/operation-not-allowed':
                return 'このメールアドレスは使用できません';
            case 'auth/weak-password':
                return 'パスワードが簡単すぎます';
            case 'auth/internal-error':
                return '認証サーバーで予期しないエラーが発生しました';
            default:
                return '登録に失敗しました';
        }
    }
    return '登録に失敗しました';
};
exports.getSignupErrorMessage = getSignupErrorMessage;
const getLoginErrorMessage = (error) => {
    console.log(error);
    if (error instanceof app_1.FirebaseError) {
        console.log(error.code);
        switch (error.code) {
            case 'auth/invalid-email':
                return 'メールアドレスの形式が正しくありません';
            case 'auth/user-disabled':
            case 'auth/user-not-found':
                return 'ユーザーが見つかりません';
            case 'auth/wrong-password':
                return 'パスワードが間違っています';
            case 'auth/invalid-credential':
                return 'メールアドレスまたはパスワードが間違っています';
            case 'auth/too-many-requests':
                return 'ログイン試行が多すぎます。時間をおいて再度お試しください';
            case 'auth/internal-error':
                return '認証サーバーで予期しないエラーが発生しました';
            default:
                return 'ログインに失敗しました';
        }
    }
    return 'ログインに失敗しました';
};
exports.getLoginErrorMessage = getLoginErrorMessage;
const getUpdateEmailErrorMessage = (error) => {
    if (error instanceof app_1.FirebaseError) {
        console.error('メールアドレス変更エラー:', error.code);
        switch (error.code) {
            case 'auth/wrong-password':
                return 'パスワードが間違っています';
            case 'auth/email-already-in-use':
                return 'このメールアドレスは既に使用されています';
            case 'auth/invalid-email':
                return 'メールアドレスの形式が正しくありません';
            case 'auth/requires-recent-login':
                return '再ログインが必要です';
            case 'auth/internal-error':
                return '認証サーバーで予期しないエラーが発生しました';
            default:
                return 'メールアドレスの変更に失敗しました';
        }
    }
    return 'メールアドレスの変更に失敗しました';
};
exports.getUpdateEmailErrorMessage = getUpdateEmailErrorMessage;
const getEmailVerificationErrorMessage = (error) => {
    if (error instanceof app_1.FirebaseError) {
        console.error('メールアドレス認証エラー:', error.code);
        switch (error.code) {
            case 'auth/invalid-action-code':
                return 'URLが不正です';
            case 'auth/expired-action-code':
                return 'URLの有効期限が切れています';
            case 'auth/user-disabled':
                return 'このユーザーは無効です';
            case 'auth/user-not-found':
                return 'ユーザーが見つかりません';
            case 'auth/internal-error':
                return '認証サーバーで予期しないエラーが発生しました';
            case 'auth/requires-recent-login':
                return '再ログインが必要です';
            default:
                return 'メールアドレスの認証に失敗しました';
        }
    }
    return 'メールアドレスの認証に失敗しました';
};
exports.getEmailVerificationErrorMessage = getEmailVerificationErrorMessage;
const isFirebaseError = (error) => {
    return error instanceof app_1.FirebaseError;
};
exports.isFirebaseError = isFirebaseError;
