import { sleep } from './sleep'

export const scrollTo = async (id: string) => {
	await sleep(100)
	const element = document.getElementById(id)
	console.info(element)
	if (element) {
		history.replaceState(null, '', `#${id}`)
		element.scrollIntoView({ behavior: 'smooth' })
	}
}

export const extractHash = (url: string): string => {
	return url.split('#')[1] ?? ''
}
