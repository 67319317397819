import { atom } from 'jotai';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOutFirebase } from '@atnd/firebase';
import { removeCookie } from '@atnd/utils';
import { api, } from '@atnd/api-client';
/**
 * ユーザー情報を管理するAtom
 */
export const userAtom = atom(undefined);
export const createUserAtom = atom(null, async (_, set, { email, organizationUrlAlias, password, postal_code, ...payload }) => {
    const hyphenRemoved = postal_code?.replace('-', '') ?? null;
    const credential = await createUserWithEmailAndPassword(email, password);
    const idToken = await credential.user.getIdToken();
    const user = await api.users.$post({
        body: {
            email,
            postal_code: hyphenRemoved,
            organization_url_alias: organizationUrlAlias !== 'all' ? organizationUrlAlias : undefined,
            ...payload,
        },
        config: {
            headers: { Authorization: `Bearer ${idToken}` },
        },
    });
    const userWithReservations = { ...user, reservations: [] };
    set(userAtom, userWithReservations);
    return userWithReservations;
});
export const updateUserAtom = atom(null, async (_, set, { id, postal_code, ...payload }) => {
    const hyphenRemoved = postal_code?.replace('-', '') ?? null;
    const updatedUser = await api.users._id(id).$patch({
        body: { ...payload, postal_code: hyphenRemoved },
    });
    set(userAtom, (prev) => (prev ? { ...prev, ...updatedUser } : undefined));
    return { id, ...payload };
});
// ユーザーに紐づく予約情報の更新
export const updateUserReservationAtom = atom(null, async (_, set, { id, ...payload }) => {
    const updatedReservation = await api.reservations._id(id).$patch({ body: payload });
    set(userAtom, (prev) => {
        if (!prev)
            return undefined;
        const reservations = prev.reservations.map((reservation) => {
            console.info(id, reservation, updatedReservation);
            return reservation.id === id ? { ...reservation, ...updatedReservation } : reservation;
        });
        return { ...prev, reservations };
    });
    return updatedReservation;
});
export const signInWithEmailAtom = atom(null, async (_, __, { email, password }) => {
    const { user } = await signInWithEmailAndPassword(email, password);
    return user;
});
export const loginAtom = atom(null, async (_, set, { idToken, organizationUrlAlias, }) => {
    const config = { headers: { Authorization: `Bearer ${idToken}` } };
    const userPromise = api.login.$get({
        config,
        query: organizationUrlAlias !== 'all' ? { organization_url_alias: organizationUrlAlias } : undefined,
    });
    const reservationsPromise = api.reservations.$get({
        config,
    });
    const [user, reservations] = await Promise.all([userPromise, reservationsPromise]);
    const userWithReservations = {
        ...user,
        reservations: reservations,
    };
    set(userAtom, userWithReservations);
});
export const logoutAtom = atom(null, async (_, set) => {
    await signOutFirebase();
    removeCookie('token');
    removeCookie('refresh_token');
    set(userAtom, undefined);
});
